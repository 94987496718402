<template>
  <div class="row">
    <input-text
      v-model="filtro.codigo"
      class="col-6"
      :label="$t('modulos.tipo_item.filtro.codigo')"
      em-filtro
    />

    <input-text
      v-model="filtro.descricao"
      class="col-6"
      :label="$t('modulos.tipo_item.filtro.descricao')"
      em-filtro
    />
  </div>
</template>

<script>
export default {
  props: {
    filtro: {},
  }
};
</script>
