<template>
  <div>
    <cabecalho-pagina
      :titulo="$t('modulos.tipo_item.titulos.listagem')"
      :total-registros="tabela.quantidadeItens"
      :titulo-botao-novo="$t('modulos.tipo_item.titulos.novo')"
      :sem-botao-ok="!buscarPermissao('TipoItem', 'Inserir')"
      :sem-filtro="!buscarPermissao('TipoItem', 'Listar')"
      @filtrar="filtrar"
      @abrirNovo="abrirNovo"
    >
      <template v-slot:filtro="{ filtro }">
        <filtro :filtro="filtro" />
      </template>
    </cabecalho-pagina>

    <botao-padrao
      v-if="podeExcluir && buscarPermissao('TipoItem', 'Deletar')"
      class="my-2"
      outlined
      color="danger"
      @click="excluirRegistros"
    >
      <v-icon>$mdiTrashCanOutline</v-icon>
      {{ $t('geral.botoes.excluir') }}
    </botao-padrao>

    <tabela-padrao
      v-model="tabela.selecionados"
      class="mt-2"
      :dados="tabela.dados"
      :colunas="tabela.colunas"
      :mostrar-checkbox="buscarPermissao('TipoItem', 'Deletar')"
      sem-paginacao
    >
      <!-- eslint-disable-next-line -->
      <template v-slot:item.acoes="{ item }">
        <dropdown-padrao
          v-if="buscarPermissao('TipoItem', 'Editar')"
          text
          color="secondary"
        >
          <template #botao>
            <v-icon> $dotsVertical </v-icon>
          </template>
          <div>
            <dropdown-padrao-item
              text
              color="secondary"
              @click="abrirEditar(item.item)"
            >
              {{ $t('geral.botoes.editar') }}
            </dropdown-padrao-item>
          </div>
        </dropdown-padrao>
      </template>
    </tabela-padrao>
  </div>
</template>
<script>
import CabecalhoPagina from '@components/layout/CabecalhoPagina.vue';
import TipoItemService from '@common/services/cadastros/TipoItemService.js';
import Filtro from './filtro.vue';
import helpers from '@/common/utils/helpers';
export default {
  components: {
    CabecalhoPagina,
    Filtro
  },

  data() {
    return {
      tabela: {
        selecionados: [],
        dados: [],
        colunas: [
          {
            value: 'acoes',
            text: '',
          },
          {
            value: 'codigo',
            text: this.$t('modulos.tipo_item.listagem.headers.codigo'),
            sortable: true,
          },
          {
            value: 'descricao',
            text: this.$t('modulos.tipo_item.listagem.headers.descricao'),
            sortable: true,
          },
        ],
        quantidadeItens: 0,
      },
      filtroAplicado: { filter: '' },

    };
  },

  computed: {
    podeExcluir: function () {
      return this.tabela.selecionados.length > 0;
    },
  },

  mounted() {
    this.listarRegistros();

    this.$store.dispatch(
      'Layout/alterarTituloPagina',
      this.$t('modulos.tipo_item.titulos.listagem')
    );

  },
  created() {
    helpers.redirecionarSemPermissao(this, 'TipoItem', 'Listar');
  },
  methods: {
    buscarPermissao(funcionalidade, funcionalidadeAcao) {
      return helpers.buscarStatePermissoes(this, funcionalidade, funcionalidadeAcao);
    },
    filtrar: function (filtro) {
      this.filtroAplicado = { ...filtro, filter: this.filtroAplicado.filter };
      this.listarRegistros();
    },

    listarRegistros: function () {
      this.$store.dispatch('Layout/iniciarCarregamento');

      let parametros = {
        ...this.filtroAplicado,
      };

      TipoItemService.listar(parametros)
        .then((res) => {
          this.tabela.dados = res.data;
          this.tabela.quantidadeItens = res.data.length;
        })
        .finally(() => {
          this.$store.dispatch('Layout/terminarCarregamento');
        });
    },

    excluirRegistros: async function () {
      this.confirmarExcluir().then(() => {
        this.$store.dispatch('Layout/iniciarCarregamento');

        let promises = [];

        this.tabela.selecionados.forEach((registro) => {
          promises.push(TipoItemService.excluir(registro.id));
        });

        Promise.all(promises)
          .then(() => {
            this.listarRegistros();

            this.toastSucesso(
              this.$t('modulos.tipo_item.exclusao_sucesso')
            );

            this.tabela.selecionados = [];
          })
          .catch(() => {
            this.toastErro(this.$t('modulos.tipo_item.exclusao_erro'));
          })
          .finally(() => {
            this.$store.dispatch('Layout/terminarCarregamento');
          });
      });
    },

    abrirNovo: function () {
      this.$router.push({ name: 'tipo-item-novo' });
    },

    abrirEditar: function (item) {
      this.$router.push({
        name: 'tipo-item-editar',
        params: { id: item.id },
      });
    },
  },
};
</script>
